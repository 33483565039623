import { apiMethods } from '../../infrastructure/api';
import { saveActiveInvoice, saveInvoices } from '../../infrastructure/store';
import type { InvoiceId } from '~/src/features/invoices';

export const useGetInvoices = () => {
	const getInvoices = apiMethods.useGetInvoices();
	const activeInvoiceCookie = useCookie<InvoiceId | null>('activeInvoiceId');
	return async () => {
		const result = await getInvoices();

		if (result.isRight()) {
			saveInvoices(result.value);

			const invoiceToSave = result.value.find(invoice => invoice.id == activeInvoiceCookie.value) || result.value[0];

			saveActiveInvoice(invoiceToSave);
		}
	};
};
