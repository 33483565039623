import { wait } from '@legacy-studio/library';
import type { Invoice, InvoiceId } from '../../types';
import { useApi } from '@/src/services/network';
import type { InvoiceType, Response } from '~/src/shared/lib';

export type InvoiceDTO = Response<{
	id: InvoiceId;
	user: string;
	balance: number;
	real_balance: number;
	bonus: number;
	turnover_sum: number;
	type: {
		id: string;
		name: string;
		code: string;
		active: string;
	};
	tournament: null;
}>;

export const toDomain = (data: InvoiceDTO): Invoice => ({
	id: data.result.id,
	balance: data.result.balance,
	realBalance: data.result?.real_balance,
	bonus: data.result.bonus,
	turnoverSum: data.result.turnover_sum,
	type: {
		id: data.result.type.id,
		name: data.result.type.name,
		code: data.result.type.code as InvoiceType,
	},
	tournament: data.result.tournament,
});

export const useRefreshDemoInvoice = () => {
	const api = useApi();

	return async () => {
		await wait(100);

		return (await api<InvoiceDTO>('/Invoices/updateDemo/')).mapRight(toDomain);
	};
};
